import * as _ from 'lodash'
import type { JReportContext } from './../../models/documents/jReport'
import {
  JSession,
  SessionScope,
  SessionStatus,
} from '~/models/sessions/JSession'
import { SESSIONS_COLLECTION_NAME } from '~/config/storage'
import { getDocumentsFromIds } from '~/controllers/documents/index'
import { usersStore } from '~/store/users'
import DbHelper from '~/helpers/dbHelper'
import { isUndefinedOrNullOrEmptyOrNan } from '~/utils/object'
import {
  Days,
  DocumentStatus,
  DocumentType,
  RecurrenceType,
} from '~/models/documents/jDocument'
import {
  createReportWithContext,
  getReportsByContext,
  getReportsByIds,
} from '~/controllers/reports/reportsController'
import type { JReport } from '~/models/documents/jReport'
import {
  getDifferenceInDays,
  getMonthDiff,
  getWeekDiff,
  getYearDiff,
} from '~/utils/date'
import { sessionStore } from '~/store/sessions'
import { routingStore } from '~/store/routing'
import type { JSiteFlags } from '~/models/sites'
import { RoutingMode } from '~/models/sites'
import apiHelper from '~/helpers/ApiHelper'
import { tCore } from '~/services/trpc'

const dbHelper = new DbHelper()

// Sessions
export const getAllSessions = async (): Promise<any[]> => {
  return await apiHelper.getSessions()
}

export const createSession = async (
  sessionObject,
  masterSession,
): Promise<JSession> => {
  const currentUser = usersStore().user
  const date = new Date()
  const session: JSession = new JSession({
    status: SessionStatus.new,
    start_date: date,
    master_session_id: masterSession.id ?? masterSession.masterSessionId,
    session_context: {
      workplace_id:
        sessionObject.session_context?.workplace_id ||
        sessionObject.workplaceId,
      production_order_id:
        sessionObject.session_context?.production_order_id || sessionObject?.id,
      product_id:
        sessionObject.session_context?.product_id || sessionObject.productId,
      operation_id:
        sessionObject.session_context?.operation_id ||
        sessionObject.operationId,
      tags: sessionObject.session_context?.tags || sessionObject.tags,
    },
    stakeholders_operator_ids: [currentUser.id],
  })

  const newSession = await session.save()
  sessionStore().addSession(newSession)
  return newSession
}

export const isSessionValid = async (
  currentSession: Record<string, any>,
  currentReport?: Record<string, any>,
) => {
  const reportIds = currentSession.reports_ids ?? []
  const allReports = await getReportsByIds(reportIds)
  const filteredAndUpdatedReports = allReports
    .filter((report) => report.id !== currentReport?.id)
    .concat(currentReport ? [currentReport] : [])

  return filteredAndUpdatedReports.every((report) => report.is_valid)
}

export const updateSessionStatus = async (
  session: object,
  status: SessionStatus,
  isValid?: boolean,
): Promise<any> => {
  await apiHelper.updateSession(session.id, {
    updateType: 'status',
    status,
    isValid: isValid ?? true,
    productionOrderId: session.production_order_id,
  })
}

export const updateSessionReports = async (
  id: string,
  reports_ids: any[],
  documents_ids: any[],
  lastTeam?: string,
): Promise<any> => {
  const currentUser = usersStore().user
  const session = await getSession(id)

  if (!session.stakeholders_operator_ids?.includes(currentUser.id))
    session.stakeholders_operator_ids.push(currentUser.id)

  return await dbHelper.updateDataToCollection(SESSIONS_COLLECTION_NAME, id, {
    reports_ids,
    documents_ids,
    stakeholders_operator_ids: session.stakeholders_operator_ids,
    last_team: lastTeam || session.last_team || '',
  })
}

export const updateSessionAttachments = async (
  id: string,
  attachmentId: any,
) => {
  const session = await getSession(id)

  if (!session.attachments_ids) session.attachments_ids = []

  session.attachments_ids.push(attachmentId)

  return await apiHelper.updateSession(session.id, {
    updateType: 'attachments_ids',
    attachments_ids: session.attachments_ids,
  })
}

export const getSession = async (
  sessionId: any,
  withReports = false,
): Promise<any> => {
  const session = await apiHelper.getSession(sessionId, withReports)

  if (!session) return
  const allDocuments = await getDocumentsFromIds(session.documents_ids)
  session.documents = {}
  session.documents.read_write_documents = allDocuments?.filter(
    (p) => p.type === DocumentType.read_write,
  )
  session.documents.readonly_documents = allDocuments?.filter(
    (p) => p.type === DocumentType.readonly,
  )

  return session
}

export const getSessions = async (sessionIds: any[]): Promise<any[]> => {
  return await dbHelper.getAllDataFromCollectionFromIds(
    SESSIONS_COLLECTION_NAME,
    sessionIds,
  )
}

export const filterSchedules = (documents) => {
  // Filter on day documents
  const day = new Date().getDay()
  const currentTime = new Date().getTime()

  // Filter on up to date documents
  const checkExpirationDate = (d: any) => {
    const expirationTimestamp =
      typeof d.expiration_date === 'string'
        ? new Date(d.expiration_date).getTime()
        : d.expiration_date

    return (
      isUndefinedOrNullOrEmptyOrNan(expirationTimestamp) ||
      expirationTimestamp > currentTime
    )
  }

  const checkStartingDate = (d: any) =>
    isUndefinedOrNullOrEmptyOrNan(d.starting_date) ||
    d.starting_date <= currentTime

  documents =
    documents?.filter((d) => checkExpirationDate(d) && checkStartingDate(d)) ??
    []

  documents =
    documents?.filter((d) => {
      const starting_date =
        d.starting_date || new Date(new Date().getFullYear(), 1, 1).getTime()
      const recurrence_starting_date =
        new Date(d.schedule?.recurrence_starting_date).getTime() ||
        new Date(new Date().getFullYear(), 1, 1).getTime()

      if (!d.schedule?.recurrenceType) return true

      if (d.schedule?.recurrenceType === RecurrenceType.day) {
        const daysDiff = getDifferenceInDays(
          starting_date,
          new Date().getTime(),
        )

        return daysDiff % d.schedule.recurrence === 0
      }
      if (d.schedule?.recurrenceType === RecurrenceType.week) {
        const week = getWeekDiff(starting_date, new Date().getTime())

        return (
          (week % d.schedule.recurrence === 0 &&
            d.schedule.days.includes(day)) ||
          d.scheduling == Days.ALL ||
          d.scheduling == String(day)
        )
      }

      // starting_date =>  recurrence_starting_date
      if (d.schedule?.recurrenceType === RecurrenceType.month) {
        const monthDiff = getMonthDiff(
          recurrence_starting_date,
          new Date().getTime(),
        )

        return (
          monthDiff % d.schedule.recurrence === 0 &&
          new Date(recurrence_starting_date).getDate() === new Date().getDate()
        )
      }
      if (d.schedule?.recurrenceType === RecurrenceType.year) {
        const yearDiff = getYearDiff(
          recurrence_starting_date,
          new Date().getTime(),
        )

        return (
          yearDiff % d.schedule.recurrence === 0 &&
          new Date(recurrence_starting_date).getDate() === new Date().getDate()
        )
      }
      return true
    }) ?? []

  documents = documents.filter((doc) => {
    if (!doc.schedule.recurrence_hours_enabled) return true

    const currentTimeString = new Date()
      .toTimeString()
      .split(':')
      .slice(0, 2)
      .join(':')

    const fitHours = doc.schedule.recurrence_hours.some(({ from, to }) => {
      return from < currentTimeString && currentTimeString < to
    })

    return fitHours
  })

  return documents
}

export const filterDocuments = async (
  filterOptions: {
    productId: string
    workplaceId: string
    scope: SessionScope
    operationId: string | null
  },
  withDraft = false,
) => {
  const { productId, workplaceId, scope, operationId } = filterOptions
  const status = withDraft
    ? [DocumentStatus.published, DocumentStatus.draft]
    : [DocumentStatus.published]

  const docs =
    scope === SessionScope.WORKPLACE
      ? await tCore.documents.filterWorkplaceDocuments.query({ workplaceId })
      : await tCore.documents.filterSessionDocuments.query({
          productId,
          workplaceId,
          operationId,
          status,
        })

  return docs
}

export const splitAndOrderDocuments = (reports, documents) => {
  return {
    read_write_reports: reports.filter((report) =>
      documents.read_write_documents.some(
        (readWriteDoc) => readWriteDoc.id === report.document_id,
      ),
    ),
    readonly_reports: reports.filter((report) =>
      documents.readonly_documents.some(
        (readWriteDoc) => readWriteDoc.id === report.document_id,
      ),
    ),
  }
}

export const getReportLastInputUpdate = (report) => {
  return _.max(
    report?.inputData?.map((input_data) =>
      new Date(input_data.update_date).getTime(),
    ),
  )
}

export const filterOperatorReports = async (freshReports: any[]) => {
  const reportsById = _.groupBy(freshReports, 'document_id')

  const finalReports = []
  // From each document, take only the last one in case the same team worked several times on the same document
  for (const p in reportsById) {
    const items = reportsById[p]
    const orderedItems = _.orderBy(items, ['update_date'], ['desc'])
    const report = _.take(orderedItems, 1)
    finalReports.push(report[0])
  }

  return finalReports
}

export const getSessionByParameters = async (
  productionOrderId: string,
  workplaceId: string,
  operationId?: string | null,
) => {
  const currentUser = usersStore().user
  const where_constraints = [
    {
      field: 'client_id',
      compare: '==',
      value: currentUser.client_id,
    },
  ]
  if (productionOrderId) {
    where_constraints.push({
      field: 'session_context.production_order_id',
      compare: '==',
      value: productionOrderId,
    })
  }

  if (workplaceId) {
    where_constraints.push({
      field: 'session_context.workplace_id',
      compare: '==',
      value: workplaceId,
    })
  }

  if (operationId) {
    where_constraints.push({
      field: 'session_context.operation_id',
      compare: '==',
      value: operationId,
    })
  }

  const sessions = await dbHelper.getAllDataFromCollectionWithAll(
    SESSIONS_COLLECTION_NAME,
    {
      where_constraints,
    },
  )

  return sessions[0]
}

export const getOrCreateWorkplaceReports = async (workplaceId: string) => {
  try {
    const isInAllWorkplaces = workplaceId === 'all'
    let contextReportsFilter: JReportContext = {
      workplace_id: workplaceId,
      product_id: 'ALL_PRODUCTS',
      operation_id: '',
    }

    if (isInAllWorkplaces)
      contextReportsFilter = _.omit(contextReportsFilter, 'workplace_id')

    let reports: JReport[] = []
    let contextDocuments = await tCore.documents.filterWorkplaceDocuments.query(
      {
        workplaceId,
      },
    )
    let contextReports: JReport[] = await getReportsByContext(
      contextReportsFilter,
      true,
    )

    contextDocuments = filterSchedules(contextDocuments)
    const documentIds = contextDocuments.map((d) => d.id)

    contextReports = contextReports.filter((report: JReport) => {
      return documentIds.includes(report.document_id || '') // Keep reports with matching document_id
    })

    const docIdsReportToCreate = _.difference(
      contextDocuments.map((p) => p.id),
      contextReports.map((p) => p.document_id),
    )
    if (docIdsReportToCreate.length > 0 && !isInAllWorkplaces) {
      for (let i = 0; i < docIdsReportToCreate.length; i++) {
        const doc = contextDocuments.find(
          (doc) => doc.id === docIdsReportToCreate[i],
        )
        reports.push(await createReportWithContext(doc, workplaceId))
      }
    }
    reports = reports.concat(contextReports)

    return { reports, contextDocuments }
  } catch (e) {
    console.error('Cannot get workplace documents', e)
  }
}

export const getRoutingWorkorders = (
  productionOrders: any,
  flags: JSiteFlags,
): any[] => {
  if (
    !flags?.application_fields?.operations ||
    flags?.routing_mode !== RoutingMode.MANUAL
  )
    return productionOrders

  const routing = routingStore().getRouting
  const workplaceRoutingMap = new Map()

  // Create a map of workplaceId -> routing array for O(1) lookup
  routing.forEach((e) => {
    if (!workplaceRoutingMap.has(e.workplaceId))
      workplaceRoutingMap.set(e.workplaceId, [])

    workplaceRoutingMap.get(e.workplaceId).push(e.operationId)
  })

  // Create a map for unique orders based on workplaceId and id
  const orderMapKey = (order) => `${order.workplaceId}_${order.id}`
  const uniqueOrdersMap = new Map()

  productionOrders.forEach((order) => {
    uniqueOrdersMap.set(orderMapKey(order), order)
  })

  const routingWorkorders = [] as any[]

  uniqueOrdersMap.forEach((po) => {
    const operationIds = workplaceRoutingMap.get(po.workplaceId) || []
    operationIds.forEach((operationId) => {
      routingWorkorders.push({
        id: po.id,
        productId: po.productId,
        updateDate: po.updateDate,
        workplaceId: po.workplaceId,
        operationId,
        tags: po.tags,
        quantity: po.quantity,
      })
    })
  })

  return routingWorkorders
}

export const filterExistingSessions = (
  productionOrders: any,
  routingWorkorders: any,
  flags: JSiteFlags,
  sessions: JSession[],
): any[] => {
  const workorders = flags?.pilot_mode ? productionOrders : routingWorkorders

  // Create a set for O(1) lookup times
  const sessionSet = new Set()
  sessions?.forEach((session) => {
    const { production_order_id, operation_id, workplace_id } =
      session?.session_context
    const key = flags?.application_fields?.operations
      ? `${production_order_id}_${operation_id}_${workplace_id}`
      : `${production_order_id}_${workplace_id}`
    sessionSet.add(key)
  })

  // Filter the workorders using the set for quick lookups
  return workorders?.filter((po) => {
    const key = flags?.application_fields?.operations
      ? `${po.id}_${po.operationId}_${po.workplaceId}`
      : `${po.id}_${po.workplaceId}`
    return !sessionSet.has(key)
  })
}
