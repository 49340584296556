<script setup lang="ts">
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'
import _ from 'lodash'
import { ModalType } from '~/models/Global'
import { Icons } from '~/models/Icon'

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
  width: {
    type: Object,
    required: false,
    default: () => ({ inner: '', outer: '' }),
  },
  type: {
    type: String,
    required: false,
    default: 'success',
  },
  modalTitle: {
    type: String,
    required: false,
    default: '',
  },
  modalTitleStyle: {
    type: String,
    required: false,
    default: '',
  },
  moreTitle: {
    type: String,
    required: false,
    default: '',
  },
  modalTitleVariables: {
    type: Object,
    required: false,
    default: {} as any,
  },
  modalDescription: {
    type: String,
    required: false,
    default: '',
  },
  modalDescriptionPluralisation: {
    type: Number,
    required: false,
    default: 1,
  },
  saveText: {
    type: String,
    required: false,
    default: 'global.confirm',
  },
  additionalButtonText: {
    type: String,
    required: false,
    default: '',
  },
  isCrossClose: {
    type: Boolean,
    required: false,
    default: false,
  },
  closeText: {
    type: String,
    required: false,
    default: 'global.cancel',
  },
  icon: {
    required: false,
    default: false,
  },
  displayBtns: {
    type: Boolean,
    required: false,
    default: true,
  },
  customBtns: {
    type: Boolean,
    required: false,
    default: false,
  },
  displaySaveBtn: {
    type: Boolean,
    required: false,
    default: true,
  },
  displayCloseBtn: {
    type: Boolean,
    required: false,
    default: true,
  },
  loadingBtn: {
    type: Boolean,
    required: false,
    default: false,
  },
  disableSaveBtn: {
    type: Boolean,
    required: false,
    default: false,
  },
  topModal: {
    type: Boolean,
    required: false,
    default: false,
  },
  isDisplayIcon: {
    type: Boolean,
    required: false,
    default: true,
  },
  allowFullScreen: {
    type: Boolean,
    required: false,
    default: false,
  },
  fullscreen: {
    type: Boolean,
    required: false,
    default: false,
  },
  dataCy: {
    type: String,
    required: false,
    default: '',
  },
  errorMessage: {
    type: String,
    required: false,
    default: '',
  },
  overflowStyle: {
    type: String,
    required: false,
    default: 'overflow-auto',
  },
  modalStyle: {
    type: String,
    required: false,
    default: 'normal' as 'normal' | 'new' | 'big',
  },
  noPadding: {
    type: Boolean,
    default: false,
    required: false,
  },
  maxWidth: {
    type: Boolean,
    default: true,
    required: false,
  },
})

const emit = defineEmits<{
  (e: 'save'): void
  (e: 'additionalButton'): void
  (e: 'actionOnClose'): void
  (e: 'update:modelValue', val: boolean): void
}>()

const route = useRoute()
const state = reactive({
  icon: props.icon
    ? props.icon
    : props.type === ModalType.success
      ? Icons.CERTIFICATE_CHECK
      : Icons.WARNING,
  fullScreen: props.fullscreen,
})

watch(
  () => props.icon,
  (newVal) => {
    state.icon = props.icon
      ? props.icon
      : props.type === ModalType.success
        ? Icons.CERTIFICATE_CHECK
        : Icons.WARNING
  },
  {
    immediate: true,
  },
)

const errorMessage = computed(() => {
  return props.errorMessage
})

const theme = {
  success: {
    button: 'bg-primary hover:bg-primary/90',
    text: 'text-primary',
    icon: 'bg-primary',
    displayConfirm: false,
  },
  error: {
    button: 'bg-cherryred-700 hover:bg-cherryred-700/90',
    text: 'text-cherryred-700',
    icon: 'bg-cherryred-700',
    displayConfirm: true,
  },
  newError: {
    button: 'bg-error-500 hover:bg-error-500/90',
    text: 'text-error-500',
    icon: 'bg-error-100 !text-error-500',
    displayConfirm: true,
  },
}

const widthManager = (): { outer: string; inner: string } => {
  const result = {
    outer:
      props.modalStyle === 'normal'
        ? 'sm:my-8 sm:max-w-lg sm:w-full'
        : 'w-[700px] sm:my-8',
    inner: '',
  }

  if (props.modalStyle === 'big') result.outer = 'w-[1200px] sm:my-8'

  if (!_.isEmpty(props.width.outer))
    result.outer = state.fullScreen ? 'w-full' : props.width.outer

  if (!_.isEmpty(props.width.inner)) result.inner = props.width.inner

  return result
}

const closeModal = () => {
  emit('update:modelValue', false)

  setTimeout(() => emit('actionOnClose'), 500)
}
</script>

<template>
  <TransitionRoot
    appear
    as="template"
    :show="props.modelValue"
  >
    <Dialog
      as="div"
      class="relative"
      :class="topModal ? 'z-110' : 'z-10'"
      @close="closeModal()"
    >
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div
          class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        />
      </TransitionChild>

      <div class="fixed inset-0 overflow-auto">
        <div
          class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
          :class="{
            'scaled-app-modal': (route.name as string).startsWith('operator'),
          }"
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="relative transform rounded-lg bg-white text-left shadow-xl transition-all"
              :class="widthManager().outer"
            >
              <div
                class="rounded-lg p-6"
                :class="{
                  'p-0': noPadding,
                  'h-screen': state.fullScreen,
                }"
                :data-cy="dataCy"
              >
                <div
                  class="items-start gap-x-6"
                  :class="{
                    flex: props.isDisplayIcon,
                    'flex flex-col gap-6': modalStyle === 'new',
                  }"
                >
                  <div
                    v-if="props.isDisplayIcon"
                    class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-lg sm:mx-0 sm:h-10 sm:w-10"
                    :class="theme[props.type].icon"
                  >
                    <component
                      :is="state.icon"
                      class="h-[26px] w-[26px] text-white"
                      :class="`${theme[props.type].icon}`"
                    />
                  </div>

                  <slot name="header" />

                  <div
                    v-if="props.modalStyle === 'normal'"
                    class="mt-3 w-full text-center sm:mt-0 sm:text-left"
                    :class="widthManager().inner"
                  >
                    <div class="flex flex-row items-center justify-between">
                      <div class="inline-flex space-x-4">
                        <DialogTitle
                          as="h3"
                          class="block text-xl font-semibold"
                          :class="[
                            theme[props.type].text,
                            props.modalTitleStyle,
                          ]"
                        >
                          {{
                            `${$t(props.modalTitle, {
                              ...props.modalTitleVariables,
                            })} ${props.moreTitle}`
                          }}
                        </DialogTitle>

                        <slot name="additionnalData" />
                      </div>
                      <div class="inline-flex space-x-2">
                        <component
                          :is="
                            state.fullScreen
                              ? Icons.ARROW_COLLAPSE
                              : Icons.ARROW_EXPAND
                          "
                          v-if="props.allowFullScreen"
                          class="cursor-pointer font-bold"
                          :class="{
                            'mt-1 h-6 w-6': state.fullScreen,
                            'mt-1.5 h-5 w-5': !state.fullScreen,
                          }"
                          :data-cy="`${dataCy}-fullscreen`"
                          @click="state.fullScreen = !state.fullScreen"
                        />
                        <component
                          :is="Icons.CLOSE"
                          v-if="props.isCrossClose"
                          class="h-8 w-8 cursor-pointer font-bold"
                          :data-cy="`${dataCy}-close`"
                          @click="closeModal()"
                        />
                      </div>
                    </div>
                    <div class="mt-4">
                      <p class="text-justify text-sm text-cloudygray-700">
                        {{
                          $t(
                            props.modalDescription,
                            modalDescriptionPluralisation,
                          )
                        }}
                      </p>
                    </div>
                    <div
                      :class="{
                        'max-w-[700px]': props.maxWidth,
                      }"
                    >
                      <slot name="body" />
                    </div>
                  </div>

                  <div
                    v-if="
                      props.modalStyle === 'new' || props.modalStyle === 'big'
                    "
                    class="mt-3 w-full text-center sm:mt-0 sm:text-left"
                    :class="widthManager().inner"
                  >
                    <div class="flex flex-row items-center justify-between">
                      <div class="inline-flex space-x-4">
                        <DialogTitle
                          v-if="props.type === 'normal'"
                          as="h3"
                          class="block text-xl font-semibold"
                          :class="theme[props.type].text"
                        >
                          {{
                            $t(props.modalTitle, {
                              ...props.modalTitleVariables,
                            })
                          }}
                        </DialogTitle>

                        <DialogTitle
                          v-else
                          as="h3"
                          class="block text-lg font-semibold"
                        >
                          {{
                            $t(props.modalTitle, {
                              ...props.modalTitleVariables,
                            })
                          }}
                        </DialogTitle>
                        <slot name="additionnalData" />
                      </div>
                      <component
                        :is="Icons.CLOSE"
                        v-if="props.isCrossClose"
                        class="h-6 w-6 cursor-pointer font-bold"
                        :data-cy="`${dataCy}-close`"
                        @click="closeModal()"
                      />
                    </div>
                    <p
                      class="mb-6 mt-4 text-justify text-sm font-normal text-[#1E1E1E]"
                    >
                      {{
                        $t(
                          props.modalDescription,
                          modalDescriptionPluralisation,
                        )
                      }}
                    </p>
                    <div class="max-h-[80vh]">
                      <slot name="body" />
                    </div>
                  </div>
                </div>
              </div>

              <div
                v-if="props.displayBtns"
                class="items-center gap-x-2 rounded-b-lg bg-gray-100 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6"
              >
                <o-button
                  v-if="props.displaySaveBtn"
                  :loading="props.loadingBtn"
                  big
                  :bg-css-color="theme[props.type].button"
                  :is-disabled="props.disableSaveBtn"
                  data-cy="confirm-modal-button"
                  @click="emit('save')"
                >
                  {{ $t(props.saveText) }}
                </o-button>
                <o-button
                  v-if="props.additionalButtonText"
                  :loading="props.loadingBtn"
                  big
                  :bg-css-color="theme[props.type].button"
                  :is-disabled="props.disableSaveBtn"
                  data-cy="additional-modal-button"
                  @click="emit('additionalButton')"
                >
                  {{ $t(props.additionalButtonText) }}
                </o-button>
                <o-button
                  v-if="props.displayCloseBtn"
                  big
                  alternate
                  data-cy="cancel-modal-button"
                  @click="closeModal()"
                >
                  {{ $t(props.closeText) }}
                </o-button>
                <span
                  v-if="errorMessage?.length"
                  class="text-error-500"
                >
                  {{ errorMessage }}</span
                >
              </div>
              <div
                v-if="props.customBtns"
                class="items-center gap-x-2 rounded-b-lg bg-gray-100 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6"
              >
                <slot name="customBtns" />

                <span
                  v-if="errorMessage?.length"
                  class="text-error-500"
                >
                  {{ errorMessage }}</span
                >
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<style>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.z-110 {
  z-index: 110;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
</style>
